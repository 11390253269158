<template>
  <div>
    <div class="bg">

    </div>
    <div style="position:fixed;top: 50%;left: 50%;transform: translate(-50%,-50%)" class="container right-panel-active">
      <!-- Sign Up -->
      <div class="container__form container--signin">
        <form action="#" class="form" id="form1">
          <!--        <h2 class="form__title">Sign Up</h2>-->
          <!--        <input type="text" placeholder="User" class="input" />-->
          <!--        <input type="email" placeholder="Email" class="input" />-->
          <!--        <input type="password" placeholder="Password" class="input" />-->
          <!--        <button class="btn">Sign Up</button>-->
          注册关闭,请联系管理员添加账号!
        </form>
      </div>

      <!-- Sign In -->
      <div class="container__form container--signup">
        <form action="#" class="form" id="form2">
          <h2 class="form__title">登 录</h2>
          <input type="text" v-model="go.emailaddr" placeholder="邮箱" class="input"/>
          <input type="password" v-model="go.password" placeholder="密码" class="input"/>
          <div style="display: flex;flex-wrap: nowrap;justify-content: center;align-items: center">
            <div style="margin-right: 15px"><img style="border-radius: 0.7rem" src="/user/captcha" alt=""
                                                 onclick="this.src=`${this.src}?time=${new Date().getTime()}`"></div>
            <input type="text" v-model="go.captcha" placeholder="验证码" class="input"/>
          </div>

          <a-popover placement="top">
            <template slot="content">
              请联系管理员！
            </template>
            <a href="#" class="link">忘记密码？</a>
          </a-popover>

          <a-button type="primary" size="large" @click="login(go)" style="width: 85%">登 录</a-button>
        </form>
      </div>

      <!-- Overlay -->
      <div class="container__overlay">
        <div class="overlay">
          <div class="overlay__panel overlay--left">
            <button class="btn" id="signIn" style="display:none">注 册</button>
          </div>
          <div class="overlay__panel overlay--right">
            <button class="btn" id="signUp">登 录</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "user-login",
  data() {
    return {
      go: {
        emailaddr: '',
        password: '',
        captcha: ''
      }
    }
  },
  methods: {
    ...mapActions('baoyue', ['login'])

  },
  mounted() {
    const signInBtn = document.getElementById("signIn");
    const signUpBtn = document.getElementById("signUp");
    const fistForm = document.getElementById("form1");
    const secondForm = document.getElementById("form2");
    const container = document.querySelector(".container");

    signInBtn.addEventListener("click", () => {
      container.classList.remove("right-panel-active");
    });

    signUpBtn.addEventListener("click", () => {
      container.classList.add("right-panel-active");
    });

    fistForm.addEventListener("submit", (e) => e.preventDefault());
    secondForm.addEventListener("submit", (e) => e.preventDefault());
  }
}
</script>

<style scoped>

* {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("~@/assets/login-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.form__title {
  font-weight: 300;
  margin: 0 0 1.25rem;
  font-size: 24px;
}

.link {
  color: #333;
  font-size: 0.9rem;
  margin: 1.5rem 0;
  text-decoration: none;
}

.container {
  background-color: #e9e9e9;
  border-radius: 0.7rem;
  box-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.25),
  0 0.7rem 0.7rem rgba(0, 0, 0, 0.22);
  height: 420px;
  max-width: 758px;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.container__form {
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.6s ease-in-out;
}

.container--signin {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .container--signin {
  transform: translateX(100%);
}

.container--signup {
  left: 0;
  opacity: 0;
  width: 50%;
  z-index: 1;
}

.container.right-panel-active .container--signup {
  animation: show 0.6s;
  opacity: 1;
  transform: translateX(100%);
  z-index: 5;
}

.container__overlay {
  height: 100%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: transform 0.6s ease-in-out;
  width: 50%;
  z-index: 100;
}

.container.right-panel-active .container__overlay {
  transform: translateX(-100%);
}

.overlay {
  background: #008997 url("~@/assets/overlay.png") no-repeat fixed center;
  background-size: cover;
  height: 100%;
  left: -100%;
  position: relative;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  width: 200%;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay__panel {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  width: 50%;
}

.overlay--left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay--left {
  transform: translateX(0);
}

.overlay--right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay--right {
  transform: translateX(20%);
}

.btn {
  background-color: #0367a6;
  background-image: linear-gradient(90deg, #0367a6 0%, #008997 74%);
  border-radius: 20px;
  border: 1px solid #0367a6;
  color: #e9e9e9;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 0.9rem 4rem;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

.form > .btn {
  margin-top: 1.5rem;
}

.btn:active {
  transform: scale(0.95);
}

.btn:focus {
  outline: none;
}

.form {
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 3rem;
  height: 100%;
  text-align: center;
}

.input {
  background-color: #fff;
  border: none;
  padding: 0.9rem 0.9rem;
  margin: 0.5rem 0;
  width: 100%;
  border-radius: 0.7rem;
}

@keyframes show {

  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}
</style>